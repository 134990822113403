import React from "react";

const Footer = () => {
    return (
        <footer className="py-1 pt-5">
        <p className="text-center mt-1 fw-bold">
          Tactical Trends Online Store - 2024-2025, All Rights Reserved
        </p>
      </footer>
  

    );
};

export default Footer;