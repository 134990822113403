import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const orderApi = createApi ({
    reducerPath: "orderApi",
    baseQuery: fetchBaseQuery({ baseUrl: "/api/v2"}),
    tagTypes: ["Order", "Orders" , "AdminOrders"],
    endpoints: (builder) => ({
        
        createNewOrder: builder.mutation({
            query(body) {
                return {
                    url: "/orders/new",
                    method: "POST",
                    body,
                };
            },

        }),

        myOrders: builder.query({
            query: ( ) => `/me/orders`,
            invalidatesTags:["Order", "Orders"],
        }),
        orderDetails: builder.query({
            query: ( id) => `/orders/${id}`,
            providesTags: ["Order"],
            invalidatesTags:["Order", "Orders"],

        }),



        stripeCheckoutSession: builder.mutation({
            query(body) {
                return {
                    url: "/payment/checkout_session",
                    method: "POST",
                    body,
                };
            },

        }),
        getDashboardSales: builder.query({
            query: ( {startDate, endDate}) => `/admin/get_sales/?startDate=${startDate}&endDate=${endDate}`,

        }),
        getAdminOrders: builder.query({
            query: ( ) => `/admin/orders`,
            providesTags:["AdminOrders"],

        }),

        updateOrder: builder.mutation({
            query({id, body}) {
                return {
                    url: `/admin/orders/${id}`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags:["Order", "AdminOrders"],

        }),

        deleteOrder: builder.mutation({
            query(id) {
                return {
                    url: `/admin/orders/${id}`,
                    method: "DELETE",
                   
                };
            },
            invalidatesTags:["AdminOrders"],

        }),




    }),
});

export const { useCreateNewOrderMutation,
               useStripeCheckoutSessionMutation, 
               useMyOrdersQuery, 
               useOrderDetailsQuery, 
               useLazyGetDashboardSalesQuery,
               useGetAdminOrdersQuery,
               useUpdateOrderMutation,
               useDeleteOrderMutation } = orderApi;